<script>
import { mapGetters } from "vuex";
import CvPdf from "@/components/CvPdf";
import html2pdf from "html2pdf.js";

export default {
    name: "CVDownload",
    
    components: {
        CvPdf
    },
    
    computed: {
        fileName() {
            let txt;
            
            if (this.lang === "pl") {
                txt = "CV-Szymon_Gołębiewski";
            } else if (this.lang === "en") {
                txt = "Resume-Szymon_Gołębiewski";
            }
            
            return txt;
        },
        
        ...mapGetters({
            lang: "lang/getLang"
        })
    },
    
    mounted() {
        document.addEventListener("keyup", event => {
            if (event.keyCode === 13) {
                setTimeout(() => {
                    this.printDownload();
                }, 1500);
            }
        });
    },
    
    methods: {
        async printDownload() {
            const cv = document.querySelector("#app");
            const opt = {
                margin: 0,
                filename: this.fileName,
                image: {
                    type: "jpeg",
                    quality: 0.5
                },
                html2canvas: {
                    scale: 4
                },
                jsPDF: {
                    unit: "in",
                    format: "letter",
                    orientation: "portrait"
                }
            };
            
            html2pdf().set(opt).from(cv).save();
        }
    }
};
</script>

<template>
    <CvPdf :isDownload="true" />
</template>