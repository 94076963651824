<script>
import { mapGetters } from "vuex";
import vue3StarRatings from "vue3-star-ratings";

export default {
    name: "CvPdf",
    
    props: {
        isDownload: {
            type: Boolean,
            default: false
        }
    },
    
    components: {
        vue3StarRatings
    },
    
    computed: {
        ...mapGetters({
            skills: "skills/getSkills"
        })
    }
};
</script>

<template>
    <div :class="['container', { 'px-0' : isDownload }]">
        <div class="cv">
            <div class="cv-row">
                <div class="cv-personal">
                    <div class="cv-personal-header">
                        <h1 class="cv-personal-header-title">
                            Szymon Gołębiewski
                        </h1>
                        <div class="cv-personal-header-img">
                            <img
                                :src="assets('/images/me/me-cv.png')"
                                alt="Szymon Gołębiewski"
                            >
                        </div>
                    </div>
                    <div class="cv-personal-contact">
                        <h1
                            v-text="$t('cv.personal_details')"
                            class="title-section"
                        />
                        <p class="fw-normal">
                            <span
                                v-text="$t('cv.address')"
                                class="fw-bold"
                            /><br>
                            Lipków, ul. Jakubowicza 100
                        </p>
                        <p>
                            <span
                                v-text="$t('cv.date_of_birth')"
                                class="fw-bold"
                            /><br>
                            11.11.1998r.
                        </p>
                        <p>
                            <span
                                v-text="$t('cv.telephone')"
                                class="fw-bold"
                            /><br>
                            <a
                                href="tel:531476572"
                            >
                                + 531-476-572
                            </a>
                        </p>
                        <p>
                            <span
                                v-text="$t('cv.email')"
                                class="fw-bold"
                            /><br>
                            <a
                                href="mailto:szgolebiewski_256@wp.pl"
                            >
                                szgolebiewski_256@wp.pl
                            </a>
                        </p>
                    
                        <!--                        <p class="mb-4">-->
                        <!--                            <span class="fw-bold">Strona www</span><br>-->
                        <!--                            <a-->
                        <!--                                href="#"-->
                        <!--                            >-->
                        <!--                                front4u.io-->
                        <!--                            </a>-->
                        <!--                        </p>-->
    
                        <h1
                            v-text="$t('cv.languages')"
                            class="title-section"
                        />
                        <p
                            v-text="$t('cv.polish')"
                            class="mb-0"
                        />
                        <p
                            v-text="$t('cv.english')"
                            class="mb-0"
                        />
                    </div>
                </div>
                <div class="cv-data">
                    <div class="cv-data-section cv-data-experience">
                        <h1
                            v-text="$t('cv.experience')"
                            class="title-section"
                        />
                        <div class="row">
                            <div class="col-12 col-sm-3">
                                2020.10 - {{ $t("cv.until_now") }}
                            </div>
                            <div class="col-12 col-sm-9">
                                <p class="fw-bold mb-0">
                                    Frontend Developer
                                </p>
                                <p class="mb-2">
                                    Codema Software House
                                </p>
                                <p
                                    v-text="$t('cv.my_duties')"
                                    class="fw-bold mb-0"
                                />
                                <ul class="mb-2">
                                    <li v-text="$t('cv.codema_duties1')" />
                                    <li v-text="$t('cv.codema_duties2')" />
                                </ul>
                                <p
                                    v-text="$t('cv.technologies_used')"
                                    class="fw-bold mb-0"
                                />
                                <ul>
                                    <li v-text="$t('cv.codema_technologies')" />
                                </ul>
                            </div>
                        </div>
    
                        <div class="row">
                            <div class="col-12 col-sm-3">
                                2018.07 – 2020.10
                            </div>
                            <div class="col-12 col-sm-9">
                                <p class="fw-bold mb-0">
                                    Junior WebDeveloper
                                </p>
                                <p class="mb-2">
                                    Netventure Sp. z o.o
                                </p>
                                <p
                                    v-text="$t('cv.my_duties')"
                                    class="fw-bold mb-0"
                                />
                                <ul class="mb-2">
                                    <li v-text="$t('cv.syskonf_duties1')" />
                                </ul>
                                <p
                                    v-text="$t('cv.technologies_used')"
                                    class="fw-bold mb-0"
                                />
                                <ul>
                                    <li v-text="$t('cv.syskonf_technologies')" />
                                </ul>
                            </div>
                        </div>
    
                        <div class="row">
                            <div class="col-12 col-sm-3">
                                2018.03
                            </div>
                            <div class="col-12 col-sm-9">
                                <p class="fw-bold mb-0">
                                    Freelancer
                                </p>
                                <p
                                    v-text="$t('cv.truck_info')"
                                    class="mb-2"
                                />
                                <p
                                    v-text="$t('cv.my_duties')"
                                    class="fw-bold mb-0"
                                />
                                <ul class="mb-2">
                                    <li v-text="$t('cv.truck_duties1')" />
                                    <li v-text="$t('cv.truck_duties2')" />
                                </ul>
                                <p
                                    v-text="$t('cv.technologies_used')"
                                    class="fw-bold mb-0"
                                />
                                <ul class="mb-0">
                                    <li v-text="$t('cv.truck_technologies1')" />
                                    <li v-text="$t('cv.truck_technologies2')" />
                                </ul>
                            </div>
                        </div>
                    </div>
    
                    <div class="cv-data-section cv-data-education">
                        <h1
                            v-text="$t('cv.education')"
                            class="title-section"
                        />
                        <div class="row">
                            <div class="col-12 col-sm-3">
                                2018.09-2022.07
                            </div>
                            <div class="col-12 col-sm-9">
                                <p
                                    v-text="$t('cv.aeh')"
                                    class="fw-bold mb-0"
                                />
                                <p class="mb-2">
                                    <span class="fw-bold">{{ $t("cv.science_direction") }}</span> {{ $t("cv.aeh_science") }}
                                </p>
                                <p class="mb-2">
                                    <span class="fw-bold">{{ $t("cv.academic_title") }}</span> {{ $t("cv.it_engineer") }}
                                </p>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-12 col-sm-3">
                                2014.09-2018.05
                            </div>
                            <div class="col-12 col-sm-9">
                                <p
                                    v-text="$t('cv.zs10')"
                                    class="fw-bold mb-0"
                                />
                                <p class="mb-2">
                                    <span class="fw-bold">{{ $t("cv.science_direction") }}</span> {{ $t("cv.it_specialist") }}
                                </p>
                                <p
                                    v-text="$t('cv.qualifications')"
                                    class="fw-bold mb-0"
                                />
                                <ul class="mb-0">
                                    <li v-text="$t('cv.e12')" />
                                    <li v-text="$t('cv.e13')" />
                                    <li v-text="$t('cv.e14')" />
                                </ul>
                            </div>
                        </div>
                    </div>
    
                    <div
                        v-if="isDownload"
                        class="html2pdf__page-break"
                    />
                    
                    <div class="cv-data-section cv-data-skills">
                        <h1
                            v-text="$t('cv.skills')"
                            class="title-section"
                        />
                        <ul>
                            <li
                                v-for="(skill, index) in skills"
                                :key="`skill_${index}`"
                            >
                                <p class="text-center">
                                    {{ skill.name }}<br>
                                    <vue3StarRatings
                                        v-model="skill.rating"
                                        :showControl="false"
                                        :disableClick="true"
                                        inactiveColor="#ddd"
                                        starSize="12"
                                        class="my-0 py-0"
                                    />
                                </p>
                            </li>
                        </ul>
                    </div>
    
                    <div class="cv-data-section cv-data-about">
                        <h1
                            v-text="$t('cv.hobbies')"
                            class="title-section"
                        />
                        <p
                            v-text="$t('cv.sport')"
                            class="mb-0"
                        />
                        <p
                            v-text="$t('cv.technology_news')"
                            class="mb-0"
                        />
                        <p
                            v-text="$t('cv.motorization')"
                            class="mb-0"
                        />
                    </div>
    
                    <div class="cv-data-section cv-data-about">
                        <h1
                            v-text="$t('cv.about_myself')"
                            class="title-section"
                        />
                        <p v-text="$t('cv.about_me')" />
                    </div>
                    
                    <p
                        v-text="$t('cv.agreement')"
                        class="agreement my-5"
                    />
                    
                    <div
                        v-if="isDownload"
                        style="padding-bottom: 352px;"
                    />
                </div>
            </div>
        </div>
    </div>
</template>